import { register, init, getLocaleFromNavigator } from 'svelte-i18n'

register('en', () => import('./locale/en.json'))
register('hi', () => import('./locale/hi.json'))
register('es', () => import('./locale/es.json'))
register('ar', () => import('./locale/ar.json'))
register('fr', () => import('./locale/fr.json'))
register('de', () => import('./locale/de.json'))
register('pt', () => import('./locale/pt.json'))

//svelte-i18n crashes if initialLocale has commas
let prefferedLang = localStorage.getItem('lang')
const localePattern = /^[a-z]{2}-[A-Z]{2}$/i

if (!localePattern.test(prefferedLang)) {
    prefferedLang = getLocaleFromNavigator()
    localStorage.removeItem('lang')
}

init({
    fallbackLocale: 'en',
    initialLocale: prefferedLang,
    warnOnMissingMessages: import.meta.env.DEV,
})
